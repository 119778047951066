import * as React from 'react'
import Layout from '../components/layout'
// import { Link } from 'gatsby'

const Trust = () => {
    return (
        <Layout pageTitle="Kto nam zaufał">
            <p className="textBlock">
            Spółdzielnia Socjalna “Dobra Marka” jest wzorcowym przykładem przedsiębiorstwa społecznego. Pomysłodawczyni: lokalna społeczniczka, liderka, znająca potencjał bezrobotnych w Karlinie i okolicach stworzyła podmiot dający zatrudnienie osobom wykluczonym z rynku pracy. Połączyła potencjał Gminy Karlino i Stowarzyszenia na Rzecz Rozwoju Domacyńskiego Ośrodka Spotkań – założyciele Spółdzielni w 2018 roku.</p>
            <p className="textBlock">
Spółdzielnia Socjalna Dobra Marka jest miejscem nauki i inspiracji. Powstała z potrzeby serca i rynku, jako szwalnia-podwykonawca. W momencie kryzysu, tak jak Rzym uratowały gęsi tak DM uratowały koty i gryzonie, gdyż podjęła się ona zlecenia, śmiesznego dla konkurencji: tworzyła domki-rakiety dla papug, śpiworki-nietoperze dla szczurów itp. Realizowała każdą fantazję zleceniodawcy. Dobra Marka ciągle się rozwija: szyje, pakuje, edukuje, doradza i pomaga. DM, to nie tylko nazwa ale i misja firmy, to pracownicy oraz produkty tworzone z pasją i radością.
            </p>
           
        </Layout>
    )
}

export default Trust